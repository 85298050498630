<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        A solution is prepared by dissolving
        <number-value :value="mol" unit="\text{mol}" />
        of glucose in enough water to form a
        <number-value :value="volume" unit="\text{mL}" />
        solution. Determine the <b>molarity</b> of the glucose solution.
      </p>

      <calculation-input
        v-model="inputs.molarity"
        prepend-text="$\ce{[Glucose]:}$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';

export default {
  name: 'Question384',
  components: {
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molarity: null,
      },
    };
  },
  computed: {
    mol() {
      return this.taskState.getValueBySymbol('mol').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
